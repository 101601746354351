//***  OPEN MODAL CEREMONIAL LOCATION   ***//

const ceremonyModal = document.getElementById('modalCeremony');
ceremonyModal.style.display = 'none';

const openCeremonyModal = document.getElementById('openCeremony');
openCeremonyModal.addEventListener('click', () => {
	ceremonyModal.style.display = 'flex';
});

const closeCeremonialsModalBtn = document.getElementById(
	'closeCeremonialsModalBtn'
);
closeCeremonialsModalBtn.addEventListener('click', () => {
	ceremonyModal.style.display = 'none';
});

const openCeremonialMap = document.getElementById('redirectMapCeremonial');
openCeremonialMap.addEventListener('click', () => {
	window.open(
		'https://maps.app.goo.gl/KxvFnKRNidHkcWKG6',
		'_blank'
	);
});

window.addEventListener('click', (event) => {
	if (event.target === ceremonyModal) {
		ceremonyModal.style.display = 'none';
	}
});

//***  OPEN MODAL CELEBRATION LOCATION   ***//

const celebrationModal = document.getElementById('modalCelebration');
celebrationModal.style.display = 'none';

const openCelebrationModal = document.getElementById('openCelebration');
openCelebrationModal.addEventListener('click', () => {
	celebrationModal.style.display = 'flex';
});

const closeModalCelebration = document.getElementById('closeModalCelebration');
closeModalCelebration.addEventListener('click', () => {
	celebrationModal.style.display = 'none';
});

const openCelebrationMap = document.getElementById('redirectMapCelebration');
openCelebrationMap.addEventListener('click', () => {
	window.open(
		'https://maps.app.goo.gl/KxvFnKRNidHkcWKG6',
		'_blank'
	);
});

window.addEventListener('click', (event) => {
	if (event.target === celebrationModal) {
		celebrationModal.style.display = 'none';
	}
});

//***  OPEN MODAL TABLE GIFT   ***//

const modalTableGift = document.getElementById('modalTableGift');
modalTableGift.style.display = 'none';

const btnOpenModalGift = document.getElementById('btnOpenModalGift');
btnOpenModalGift.addEventListener('click', () => {
	modalTableGift.style.display = 'flex';
});

const closeModalTableGift = document.getElementById('closeModalTableGift');
closeModalTableGift.addEventListener('click', () => {
	modalTableGift.style.display = 'none';
});

const btnFalabella = document.getElementById('btnFalabella');
btnFalabella.addEventListener('click', () => {
	window.open('https://www.falabella.com.pe/falabella-pe', '_blank');
});

window.addEventListener('click', (event) => {
	if (event.target === modalTableGift) {
		modalTableGift.style.display = 'none';
	}
});

const copyAccount = document.getElementById('copyAccount');
copyAccount.addEventListener('click', () => {
	const account = '0022-0156-0200368345';
	navigator.clipboard
		.writeText(account)
		.then(function () {
			console.log('Texto copiado al portapapeles');
		})
		.catch(function (err) {
			console.error('Error al copiar texto: ', err);
		});
});


const copyCCI = document.getElementById('copyCCI');
copyCCI.addEventListener('click', () => {
	const CCI = 'CCI: 011-101-0022-0156-0200368345';
	navigator.clipboard
		.writeText(CCI)
		.then(function () {
			console.log('Texto copiado al portapapeles');
		})
		.catch(function (err) {
			console.error('Error al copiar texto: ', err);
		});
});


// CONFIRM ASSISTANCE 

const btnConfirmAsistance = document.getElementById('btnConfirmAsistance');

btnConfirmAsistance.addEventListener("click", () => {
	let phoneNumber = '+17742932223';
	let encodedMessage = encodeURIComponent("Hola, confirmo mi asistencia a la boda...");

	const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
	
	window.open(url, '_blank');
});


	  